import http from './http'

// 用户登录
export function login(data) {
    return http.post('/index/login/index', data)
}
// 用户注销
export function logout() {
    return http.post('')
}
