<template>
    <a-layout>
        <a-layout-sider :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
            <div class="logo">文之都工单系统</div>
            <a-menu theme="dark" mode="inline" v-model:selectedKeys="selectedKeys">
                <a-menu-item key="1" @click="menu('/slist')">
                    <upload-outlined/>
                    <span class="nav-text">工单列表</span>
                </a-menu-item>
                <a-menu-item key="2" @click="menu('/userlist')">
                    <user-outlined/>
                    <span class="nav-text">账号列表</span>
                </a-menu-item>
            </a-menu>
        </a-layout-sider>
        <a-layout :style="{ marginLeft: '200px' }">
            <a-layout-header :style="{ background: '#fff', padding: 0 }" class="tc">
                你好管理员，欢迎使用文之都工单管理系统！
            </a-layout-header>
            <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
                <router-view></router-view>
            </a-layout-content>
            <a-layout-footer :style="{ textAlign: 'center' }">
                Moonway Studio ©2021 Created by Moonway
            </a-layout-footer>
        </a-layout>
    </a-layout>
</template>
<script>
    import {
        UserOutlined,
        VideoCameraOutlined,
        UploadOutlined,
        BarChartOutlined,
        CloudOutlined,
        AppstoreOutlined,
        TeamOutlined,
        ShopOutlined,
    } from '@ant-design/icons-vue'
    import {defineComponent, ref,onMounted,getCurrentInstance,toRaw} from 'vue'
    import {useRouter,useRoute} from 'vue-router'
    import List from '@/components/service/List.vue'

    export default defineComponent({
        name: 'custom',
        setup() {
            const selectedKeys = ref(['1']);
            const { ctx } = getCurrentInstance();
            const router = useRouter();
            const menu = (s) => {
                //console.log(s)
                router.push(s)
            };
            //const route = useRoute();
            onMounted(() => {
                console.log(router.currentRoute.value.path);
                if(router.currentRoute.value.path === '/slist'){
                    selectedKeys.value =['1'];
                }else{
                    selectedKeys.value =['2'];
                }
            });
            return {
                menu,
                selectedKeys,
            }
        },

        components: {
            UserOutlined,
            VideoCameraOutlined,
            UploadOutlined,
            BarChartOutlined,
            CloudOutlined,
            AppstoreOutlined,
            TeamOutlined,
            ShopOutlined,
            List
        },
    })
</script>
<style lang="less" scoped>
    .logo {
        height: 32px;
        margin: 16px;
        font-size: 20px
    }

    .site-layout .site-layout-background {
        background: #fff;
    }

    [data-theme='dark'] .site-layout .site-layout-background {
        background: #141414;
    }
</style>
