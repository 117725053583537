<template>
    <a-layout>
        <a-layout-sider :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 }">
            <div class="logo">文之都工单系统</div>
            <a-menu theme="dark" mode="inline" v-model:selectedKeys="selectedKeys">
                <a-menu-item key="1" @click="menu('/submit')">
                    <upload-outlined/>
                    <span class="nav-text">提交工单</span>
                </a-menu-item>
                <a-menu-item key="2" @click="menu('/list')">
                    <user-outlined/>
                    <span class="nav-text">我的工单</span>
                </a-menu-item>
                <!--
                <a-menu-item key="3">
                  <upload-outlined />
                  <span class="nav-text">nav 3</span>
                </a-menu-item>
                <a-menu-item key="4">
                  <bar-chart-outlined />
                  <span class="nav-text">nav 4</span>
                </a-menu-item>
                <a-menu-item key="5">
                  <cloud-outlined />
                  <span class="nav-text">nav 5</span>
                </a-menu-item>
                <a-menu-item key="6">
                  <appstore-outlined />
                  <span class="nav-text">nav 6</span>
                </a-menu-item>
                <a-menu-item key="7">
                  <team-outlined />
                  <span class="nav-text">nav 7</span>
                </a-menu-item>
                <a-menu-item key="8">
                  <shop-outlined />
                  <span class="nav-text">nav 8</span>
                </a-menu-item>
                -->
            </a-menu>
        </a-layout-sider>
        <a-layout :style="{ marginLeft: '200px' }">
            <a-layout-header :style="{ background: '#fff', padding: 0 }" class="tc">
                你好，欢迎使用文之都工单管理系统！<small class="red">(小提示：强制刷新mac系统command+shift+r，pc的windows系统ctrl+f5，Mac电脑装的windows系统fn+ctrl+f5)</small>
            </a-layout-header>
            <a-layout-content :style="{ margin: '24px 16px 0', overflow: 'initial' }">
                <router-view></router-view>
            </a-layout-content>
            <a-layout-footer :style="{ textAlign: 'center' }">
                Moonway Studio ©2021 Created by Moonway
            </a-layout-footer>
        </a-layout>
    </a-layout>
</template>
<script>
    import {
        UserOutlined,
        VideoCameraOutlined,
        UploadOutlined,
        BarChartOutlined,
        CloudOutlined,
        AppstoreOutlined,
        TeamOutlined,
        ShopOutlined,
    } from '@ant-design/icons-vue'
    import {defineComponent, ref,onMounted,getCurrentInstance} from 'vue'
    import {useRouter} from 'vue-router'
    import Submit from '@/components/custom/Submit.vue'
    import List from '@/components/custom/List.vue'
    import Slist from '@/components/service/List.vue'

    export default defineComponent({
        name: 'Custom',
        setup() {
            const selectedKeys = ref(['1']);
            const { ctx } = getCurrentInstance();
            const router = useRouter();
            const menu = (s) => {
                console.log(s);
                //console.log(router);
                router.push(s);
            };
            onMounted(() => {
                //console.log(ctx.$router.currentRoute.value.fullPath)
                if(router.currentRoute.value.path === '/submit'){
                    selectedKeys.value =['1'];
                }else{
                    selectedKeys.value =['2'];
                }
            });
            return {
                menu,
                selectedKeys,
            }
        },

        components: {
            UserOutlined,
            VideoCameraOutlined,
            UploadOutlined,
            BarChartOutlined,
            CloudOutlined,
            AppstoreOutlined,
            TeamOutlined,
            ShopOutlined,
            Submit,
            List,
            Slist
        },
    })
</script>
<style lang="less" scoped>
    .logo {
        height: 32px;
        margin: 16px;
        font-size: 20px
    }

    .site-layout .site-layout-background {
        background: #fff;
    }

    [data-theme='dark'] .site-layout .site-layout-background {
        background: #141414;
    }

</style>
